export const orderStatuses = [
  {
    name: "Draft",
    allowDelete: true,
    allowCancel: false,
    allowEdit: true,
    allowPO: false,
    allowRevision: false,
    match: (order) =>
      ['draft'].includes(order.status.toLowerCase()) ||
      ['draft'].includes(order.status_code),
    canConfirmPayment: (order) => order?.payments?.length > 0 && order?.payments[0]?.pay_url,
    canReceiveManualPayment: (order) => false,
    showAttachment: (value) => true
  },
  {
    name: "Pending PO",
    allowDelete: false,
    allowCancel: true,
    allowEdit: false,
    allowPO: true,
    allowRevision: true,
    match: (order) => [
      "pending_po",
    ].includes(order.status_code),
    canConfirmPayment: (order) => order?.payments?.length > 0 && order?.payments[0]?.pay_url,
    canReceiveManualPayment: (order) => false,
    showAttachment: (value) => value && Array.isArray(value) && value.length > 0
  },
  {
    name: "Pending Processing",
    allowDelete: false,
    allowCancel: true,
    allowEdit: false,
    allowPO: true,
    allowRevision: true,
    match: (order) => [
      "pending_processing",
    ].includes(order.status_code),
    canConfirmPayment: (order) => order?.payments?.length > 0 && order?.payments[0]?.pay_url,
    canReceiveManualPayment: (order) => false,
    showAttachment: (value) => value && Array.isArray(value) && value.length > 0
  },
  {
    name: "Pending Payment",
    allowDelete: false,
    allowCancel: true,
    allowEdit: false,
    allowPO: false,
    allowRevision: true,
    match: (order) => [
      "pending_payment",
    ].includes(order.status_code),
    canConfirmPayment: (order) => order?.payments?.length > 0 && order?.payments[0]?.pay_url,
    canReceiveManualPayment: (order) => order?.payment_term == "MANUAL",
    showAttachment: (value) => value && Array.isArray(value) && value.length > 0
  },
  {
    name: "On Progress",
    allowDelete: false,
    allowCancel: false,
    allowEdit: false,
    allowPO: false,
    allowRevision: false,
    match: (order) => {
      return (!["pending_payment", "pending_po", "pending_processing", "draft"].includes(order.status_code) && (order.completed_at == null))
    },
    canConfirmPayment: (order) => order?.payments?.length > 0 && order?.payments[0]?.pay_url,
    canReceiveManualPayment: (order) => false,
    showAttachment: (value) => value && Array.isArray(value) && value.length > 0
  },
  {
    name: "Completed",
    allowDelete: false,
    allowCancel: false,
    allowEdit: false,
    allowPO: false,
    allowRevision: false,
    match: (order) => [
      "completed", "delivered",
    ].includes(order.status_code),
    canConfirmPayment: (order) => false,
    canReceiveManualPayment: (order) => false,
    canCancelOrder: (order) => order.payment_term == "MANUAL" && !order.require_fulfillment,
    showAttachment: (value) => value && Array.isArray(value) && value.length > 0
  },
  {
    name: "Canceled",
    allowDelete: false,
    allowCancel: false,
    allowEdit: false,
    allowPO: false,
    allowRevision: false,
    match: (order) => [
      "canceled",
    ].includes(order.status_code),
    canConfirmPayment: (order) => false,
    canReceiveManualPayment: (order) => false,
    showAttachment: (value) => value && Array.isArray(value) && value.length > 0
  },
  {
    name: "Return",
    allowDelete: false,
    allowCancel: false,
    allowEdit: false,
    allowPO: false,
    allowRevision: false,
    match: (order) => [
      "pending_return", "returned", "return_canceled",
    ].includes(order.status_code),
    canConfirmPayment: (order) => false,
    canReceiveManualPayment: (order) => false,
    showAttachment: (value) => value && Array.isArray(value) && value.length > 0
  }
]
