import clsx from 'clsx'
import {
  Image,
  Button,
  Text,
} from '.'
import {
  useTheme,
  Box,
} from "@material-ui/core"
import { Add, Description } from "@material-ui/icons"
import processImage from 'shared/utils/processImage'
import useStyles from './styles'

export function FileInput({ id, value, className, classNameImage, onUploadImage, allowDelete, onDelete, acceptedTypes = '*' }) {
  const classes = useStyles()
  const theme = useTheme()
  const { image_url, file_name, is_uploading, upload_progress } = value || {}
  const imageExt = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'tiff', 'jfif']

  const onFileChange = (e) => {
    let item = e?.target?.files[0]

     // if condition: resize file if it's an image
     if (item?.type?.includes('image')) {
      processImage(item).then(res => {
        if (onUploadImage) onUploadImage(res)
      })
    }
    // else condition: return usual file size limitation if not an image
    else {
      let maxFileSize = (1024 * 1024) * 16 // 16MB
      if (item?.size > maxFileSize) {
        alert(`Ukuran file anda (${((item.size) / (1024 * 1024)).toFixed(2)} MB) melebihi batas maksimal 16MB. Coba kembali dengan file berukuran lebih kecil.`)
        e.target.value = ''
        return;
      }
      if (onUploadImage) onUploadImage(item)
    }

    // Require to enable input field to accept value again
    e.target.value = ''
  }

  return (
    <div className={clsx(classes.imageInput, className)}>
      {image_url &&
        <>
          {imageExt.some(ext => ext.includes(image_url.split('.').pop()))
            ? <div className={classes.imageInputImage}>
              {allowDelete &&
                <div className={classes.imageInputDelete}>
                  <Button kind="delete" variant="icon" size="small" onClick={onDelete} />
                </div>
              }
              <img className={clsx(classes.imageFitHeight, classNameImage)} src={image_url} />
            </div>
            : <div className={classes.imageInputPlaceholder}>
              <label htmlFor={`perform_upload${id ? `-${id}`: ''}`}>
                {allowDelete &&
                  <div className={classes.imageInputDelete}>
                    <Button kind="delete" variant="icon" size="small" onClick={onDelete} />
                  </div>
                }
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Description />
                  <Text variant="body2">
                    {file_name}
                  </Text>
                </Box>
              </label>
            </div>
          }
        </>
      }
      {!image_url &&
        <div className={clsx(classes.imageInputPlaceholder, className)}>
          <input
            accept={acceptedTypes}
            style={{ display: 'none' }}
            id={`perform_upload${id ? `-${id}`: ''}`}
            type="file"
            onChange={onFileChange} />
          <label htmlFor={`perform_upload${id ? `-${id}`: ''}`}>
            <Box display='flex' flexDirection='column' alignItems='center' style={{ cursor: 'pointer' }}>
              <Add style={{ color: theme.palette.primary.main, marginBottom: theme.spacing(1) }} />
              <Text variant="body2">Upload</Text>
              <Text variant="caption" color='textMuted'>(Maximum 16MB)</Text>
            </Box>
          </label>
        </div>
      }
    </div>
  )
}

export default FileInput