function addFieldType(obj, fields = []) {
   const getFieldType = (keyProps) => {
      if (fields.length == 0) return ''

      const fieldFilter = [...fields].filter(f => f.key.replaceAll('-', '_') == keyProps) || []
      if (fieldFilter.length > 0) {
         const {key, ...restField} = fieldFilter[0]
         return restField
      }

      return {}
   }

   /**
    * Recursive function to add the field_type key without overwriting existing values
    */
   function traverse(o) {
      const keys = Object.keys(o);
      keys.forEach((key, index) => {
         if (Array.isArray(o[key]) && o[key].length > 0) {
            // let newArray = [...o[key]].map(item => ({ ...item, ...getFieldType(item.key) }))
            // o[key] = newArray

            o[key] = o[key].map(item => {
               const newFieldData = getFieldType(item.key);
               return {
                  ...item,
                  ...Object.fromEntries(
                     Object.entries(newFieldData).filter(([k]) => !(k in item)) // Only add missing properties
                  )
               }
            })
         } else if (typeof o[key] == 'object' && Object.keys(o[key]).length > 0) {
            // if (Object.keys(o[key]).some(s => s == 'key')) {
            //    const newObj = {...o[key], ...getFieldType(o[key].key)}
            //    o[key] = newObj
            // } else {
            //    traverse(o[key]);
            // }

            if (o[key].key) {
               const newFieldData = getFieldType(o[key].key);
               o[key] = {
                  ...o[key],
                  ...Object.fromEntries(
                     Object.entries(newFieldData).filter(([k]) => !(k in o[key])) // Only add missing properties
                  )
               };
            } else {
               traverse(o[key]);
            }
         }
      });
   }

   // Start the traversal with the initial object
   traverse(obj);
   return obj;
}

export default addFieldType